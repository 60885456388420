import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104')
];

export const server_loads = [0,3,4,5,6,7,8,9,25,26,27,10,12,11,14,15,16,17,18,23];

export const dictionary = {
		"/(app)": [28,[2]],
		"/(app)/admin/api-clients": [~29,[2,3,4]],
		"/(app)/admin/api-clients/new": [32,[2,3,4]],
		"/(app)/admin/api-clients/[clientId=clientId]": [~30,[2,3,4]],
		"/(app)/admin/api-clients/[clientId=clientId]/edit": [~31,[2,3,4]],
		"/(app)/admin/comments": [~33,[2,3,5]],
		"/(app)/admin/data-ingestion": [~34,[2,3,6]],
		"/(app)/admin/iam": [~35,[2,3,7]],
		"/(app)/admin/iam/new": [38,[2,3,7]],
		"/(app)/admin/iam/[uid=uid]": [~36,[2,3,7]],
		"/(app)/admin/iam/[uid=uid]/edit": [~37,[2,3,7]],
		"/(app)/admin/rfc": [~39,[2,3,8]],
		"/(app)/admin/system": [40,[2,3,9]],
		"/(auth)/auth/login": [101,[24,25]],
		"/(auth)/auth/logout": [~102,[24]],
		"/(auth)/auth/register": [103,[24,26]],
		"/(auth)/auth/reset": [104,[24,27]],
		"/(app)/blog": [~41,[2]],
		"/(app)/data/airports": [~42,[2]],
		"/(app)/data/airports/new": [47,[2]],
		"/(app)/data/airports/[id=id]": [~43,[2]],
		"/(app)/data/airports/[id=id]/change-history": [~44,[2]],
		"/(app)/data/airports/[id=id]/discussion": [~45,[2]],
		"/(app)/data/airports/[id=id]/edit": [~46,[2,10]],
		"/(app)/data/airspaces": [~48,[2]],
		"/(app)/data/airspaces/imports": [~53,[2,12]],
		"/(app)/data/airspaces/imports/tool": [55,[13]],
		"/(app)/data/airspaces/imports/[id=id]": [~54,[2,12]],
		"/(app)/data/airspaces/new": [56,[2]],
		"/(app)/data/airspaces/[id=id]": [~49,[2]],
		"/(app)/data/airspaces/[id=id]/change-history": [~50,[2]],
		"/(app)/data/airspaces/[id=id]/discussion": [~51,[2]],
		"/(app)/data/airspaces/[id=id]/edit": [~52,[2,11]],
		"/(app)/data/exports": [~57,[2]],
		"/(app)/data/hang-gliding-sites": [~58,[2]],
		"/(app)/data/hang-gliding-sites/new": [63,[2]],
		"/(app)/data/hang-gliding-sites/[id=id]": [~59,[2]],
		"/(app)/data/hang-gliding-sites/[id=id]/change-history": [~60,[2]],
		"/(app)/data/hang-gliding-sites/[id=id]/discussion": [~61,[2]],
		"/(app)/data/hang-gliding-sites/[id=id]/edit": [~62,[2,14]],
		"/(app)/data/hotspots": [~64,[2]],
		"/(app)/data/hotspots/new": [69,[2]],
		"/(app)/data/hotspots/[id=id]": [~65,[2]],
		"/(app)/data/hotspots/[id=id]/change-history": [~66,[2]],
		"/(app)/data/hotspots/[id=id]/discussion": [~67,[2]],
		"/(app)/data/hotspots/[id=id]/edit": [~68,[2,15]],
		"/(app)/data/latest-changes": [~70,[2]],
		"/(app)/data/navaids": [~71,[2]],
		"/(app)/data/navaids/new": [76,[2]],
		"/(app)/data/navaids/[id=id]": [~72,[2]],
		"/(app)/data/navaids/[id=id]/change-history": [~73,[2]],
		"/(app)/data/navaids/[id=id]/discussion": [~74,[2]],
		"/(app)/data/navaids/[id=id]/edit": [~75,[2,16]],
		"/(app)/data/obstacles": [~77,[2]],
		"/(app)/data/obstacles/[id=id]": [~78,[2]],
		"/(app)/data/obstacles/[id=id]/discussion": [~79,[2]],
		"/(app)/data/rc-airfields": [~80,[2]],
		"/(app)/data/rc-airfields/new": [85,[2]],
		"/(app)/data/rc-airfields/[id=id]": [~81,[2]],
		"/(app)/data/rc-airfields/[id=id]/change-history": [~82,[2]],
		"/(app)/data/rc-airfields/[id=id]/discussion": [~83,[2]],
		"/(app)/data/rc-airfields/[id=id]/edit": [~84,[2,17]],
		"/(app)/data/reporting-points": [~86,[2]],
		"/(app)/data/reporting-points/new": [91,[2]],
		"/(app)/data/reporting-points/[id=id]": [~87,[2]],
		"/(app)/data/reporting-points/[id=id]/change-history": [~88,[2]],
		"/(app)/data/reporting-points/[id=id]/discussion": [~89,[2]],
		"/(app)/data/reporting-points/[id=id]/edit": [~90,[2,18]],
		"/(app)/docs": [92,[2,19]],
		"/(app)/legal": [93,[2,20]],
		"/(app)/map": [94,[21]],
		"/(app)/notifications": [~95,[2]],
		"/(app)/tools/airspaces": [96,[22]],
		"/(app)/user/api-clients": [~97,[2,23]],
		"/(app)/user/comments": [~98,[2,23]],
		"/(app)/user/downloads": [~99,[2,23]],
		"/(app)/user/profile": [~100,[2,23]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';